import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='Cookie Policy' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Cookie Policy'
            subheader='This is the Cookie Policy for Possible fix, accessible from https://possiblefix.com'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Button as={GLink} to='/privacy' sx={styles.button}>
              Check Privacy Policy
            </Button>
          </Box>
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant='h2'>
                  What Are Cookies
                </Text>
                <Text variant='p'>
As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.
                   </Text>
                <Text variant='h2'>
                  How We Use Cookies
                </Text>
                <Text variant='p'>
We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.                  

                </Text>
                <Text variant='h2'>
                  Disabling Cookies
                </Text>

                <Text variant='p'>
You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies. 
             </Text>
                <Text variant='h2'>
                The Cookies We Set

                </Text>
                  <Text variant='p'>
                  <ul>
                    <li><b>Account related cookies</b><br /> If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</li>
                    <li><b>Login related cookies</b><br /> We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</li>
                    <li><b>Email newsletters related cookies</b><br /> This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</li>
                    <li><b>Orders processing related cookies</b><br /> This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.</li>
                    <li><b>Surveys related cookies</b><br /> From time to time we offer user surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.</li>
                    <li><b>Forms related cookies</b><br /> When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</li>
                    <li><b>Site preferences cookies</b><br /> In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences..</li>
                  </ul>
                  </Text>

                  <Text variant='h2'>
        Third Party Cookies
                </Text>  

                <Text variant='p'>
                In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.
                   </Text>  

                <Text variant='p'>
                  <ul>
 
                    <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. 
                    <br />For more information on Google Analytics cookies, see the official Google Analytics page.</li>
                    <li>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</li>
                    <li>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times that a given ad is shown to you.
                    <br />For more information on Google AdSense see the official Google AdSense privacy FAQ.</li>
                    <li>We use adverts to offset the costs of running this site and provide funding for further development. The behavioural advertising cookies used by this site are designed to ensure that we provide you with the most relevant adverts where possible by anonymously tracking your interests and presenting similar things that may be of interest.</li>
                    <li>Several partners advertise on our behalf and affiliate tracking cookies simply allow us to see if our customers have come to the site through one of our partner sites so that we can credit them appropriately and where applicable allow our affiliate partners to provide any bonus that they may provide you for making a purchase.</li>
                    <li>We also use social media buttons and or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</li>

                           </ul>
                </Text> 

                 <Text variant='h2'>
                More Information
                </Text> 

                 <Text variant='p'>
Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.
  </Text> 

                 <Text variant='p'>
 For more general information on cookies, please read the <a href="/privacy">Privacy Policy article.</a>
                </Text>
  
              </Section>
            </Box>
           

          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
